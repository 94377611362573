import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import seoo from "./seo2.jpg";
import "./vedpgcss.css";

function Seoopt() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Search Engine Optimization</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={seoo} alt="Search Engine Optimization" />
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">Search Engine Optimization</Card.Title>
              <br></br>
              
              <h8>Amplemind</h8> offers expert training in <h8>SEO
              Optimization.</h8> <br></br> Our comprehensive program covers fundamental
              principles, advanced strategies, and practical applications to
              ensure students master the art of search engine optimization. With
              a focus on driving organic traffic and improving search rankings,
              our experienced instructors guide students through keyword
              research, on-page optimization, and link-building techniques.
              Amplemind emphasizes hands-on learning with real-world projects
              and up-to-date industry insights. Whether you're a beginner or
              looking to enhance your SEO skills, Amplemind equips you with the
              knowledge and tools needed to excel in SEO Optimization. Join us
              to elevate your online presence.
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Seoopt;
