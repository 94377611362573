import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import "./vediopg.css";
import Card from "react-bootstrap/Card";
import webkit from "./webkitimg.jpg";
import sftwre from "./sftwreDev.jpg";
import intdsgn from "./intriorDsgn.jpg";
import arcdegn from "./architectD.jpg";
import elecdsgn from "./electricD.jpg";
import seo from "./seo.jpg";
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
        <Container id="contain" fluid style={{paddingTop:"100px"}}>
          <Row className="rows">
            <Col className="cols">
              <Card id="card" style={{ width: "20rem" }}>
                <Link to="/webkit">
                  <img src={webkit} alt="Webkit_course" width="100%" />
                  <div class="content">
                    <h2>Website Designing</h2>
                  </div>
                </Link>
              </Card>
            </Col>

            {/* Second column */}
            <Col className="cols">
              <Card id="card" style={{ width: "20rem" }}>
                <Link to="/bills">
                  <img
                    src={sftwre}
                    alt="Softwaredevelopment_course"
                    width="100%"
                  />
                  <div class="content">
                    <h2>Software Development</h2>
                  </div>
                </Link>
              </Card>
            </Col>

            {/* Third column hai */}

            <Col className="cols">
              <Card id="card" style={{ width: "20rem" }}>
                <Link to="/interior">
                  <img src={intdsgn} alt="Interiordesign_course" width="100%" />
                  <div class="content">
                    <h2>Interior Designing</h2>
                  </div>
                </Link>
              </Card>
            </Col>
          </Row>

          {/* Another Row */}
            <Row className="rows">
              <Col className="cols">
                
                  <Card id="card" style={{ width: "20rem" }}>
                    <Link to="/architect">
                      <img
                        src={arcdegn}
                        alt="architecturalDesign_course"
                        width="100%"
                      />
                      <div class="content">
                        <h2>Architectural Designing</h2>
                      </div>
                    </Link>
                  </Card>
                
              </Col>
              {/* Second row second column */}
              <Col className="cols">
                
                  <Card id="card" style={{ width: "20rem" }}>
                    <Link href="/elec">
                      <img
                        src={elecdsgn}
                        alt="ElectricalDesign_course"
                        width="100%"
                      />
                      <div class="content">
                        <h2>Electrical Designing</h2>
                      </div>
                    </Link>
                  </Card>
                
              </Col>

              {/* second row third column */}

              <Col className="cols">
                
                  <Card id="card" style={{ width: "18rem" }}>
                    <Link to="/seo">
                      <img
                        src={seo}
                        alt="SeoOptimization_course"
                        width="100%"
                      />
                      <div class="content">
                        <h2>S. E. O Optimization</h2>
                      </div>
                    </Link>
                  </Card>
                
              </Col>
            </Row>
        </Container>
 
    </>
  );
}
export default Services;
