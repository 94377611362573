import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./vediopg.css";
import pht from "./sample.jpg";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import AuthProvider from "./AuthContext"; // Correct name of the file
import webkit from "./webkitimg.jpg";


function Vedios() {
  const { isLoggedIn } = useContext(AuthContext);

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    return <Navigate to="/adminlog" replace />;
  }
  return (
    <>
    
      <Container id="contain">
        <Row className="rows">
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
        </Row>
        {/* Second row */}
        <Row className="rows">
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
        </Row>
        {/* Third row */}
        <Row className="rows">
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
        </Row>
        {/* Fourth row */}
        <Row className="rows">
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/vedpg">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
          <Col className="cols">
            <Card id="card" style={{ width: "18rem" }}>
              <a href="/DD">
                <img src={pht} alt="Sample" width="100%" />
                <Card.Body id="bodies">
                  <Card.Title id="titles">Watch Video</Card.Title>
                </Card.Body>
              </a>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Vedios;
