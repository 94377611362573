import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./About";
import Home from "./Home";
// import Course from "./Courses";
import Contact from "./Contact";
import Service from "./Service";
import Navigation from "./Navbar";
import Footer from "./Footer";
import Mernstack from "./pages/Mernstack";
import Python from "./pages/Python";
import DataAnalytics from "./pages/Dataanalytics";
import DataScience from "./pages/Datascience";
import Java from "./pages/Java";
import DevOps from "./pages/Devops";
import SalesForce from "./pages/Salesforce";
import C from "./pages/C";
import React from "./pages/React";
import Footer11 from "./Footer11";
import Course from "./Courses";
import Login from "./login";
import Staragile from "./Star";
import Admission from "./form";
import Services from "./servicespg";
import Vedios from "./vediopg";
import Architect from "./arcdesgnpg";
import Software from "./billing";
import Vedpg from "./vedpg";
import Seoopt from "./seoopt";
import Webkit from "./webkitInfo";
import Interior from "./interiorDsgn";
import Electric from "./electricDesgn";
import Payment from "./paymentPage";
import AdminPage from "./admin/AdminPage";
import Employelist from "./admin/Employees";
import Footerr from "./Footerr";

function Routing() {
    return ( 
        <BrowserRouter>
         <Navigation/>
           <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/Home" element={<Home/>}/>
             <Route path="/courses" element={<Course />}></Route>
             <Route path="/about" element={<About />}></Route>
             <Route path="/contact" element={<Contact />}></Route>
             <Route path="/services" element={<Services />}></Route>
             <Route path="/Mernstack" element={<Mernstack />}></Route>
             <Route path="/Python" element={<Python />}></Route>
             <Route path="/Dataanalytics" element={<DataAnalytics />}></Route>
             <Route path="/Datascience" element={<DataScience />}></Route>
             <Route path="/java" element={<Java />}></Route>
             <Route path="/Devops" element={<DevOps/>}></Route>
             <Route path="/adminlog" element={<Login/>}></Route>
             <Route path="/signup" element={<Staragile/>}></Route>
             <Route path="/form" element={<Admission/>}></Route>
             <Route path="/services" element={<Services/>}></Route>
             <Route path="/vediospg" element={<Vedios/>}></Route>
             <Route path="/architect" element={<Architect/>}></Route>
             <Route path="/bills" element={<Software/>}></Route>
             <Route path="/vedpg" element={<Vedpg/>}></Route>
             <Route path="/seo" element={<Seoopt/>}></Route>
             <Route path="/webkit" element={<Webkit/>}></Route>
             <Route path="/interior" element={<Interior/>}></Route>
             <Route path="/elec" element={<Electric/>}></Route>
             <Route path="/payment" element={<Payment/>}></Route>
             <Route path="/adminpage" element={<AdminPage/>}></Route>
             <Route path="/employlist" element={<Employelist/>}></Route>
             <Route path="/C" element={<C />}></Route>
             <Route path="/Salesforce" element={<SalesForce />}></Route>
            <Route path="/React" element={<React />}></Route>
           </Routes>
           <Footerr />
        </BrowserRouter>

     );
}

export default Routing;