import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./payment.css";
import Image from "react-bootstrap/Image";
import Qrcode from "./images/Qrcode.jpg";

import { useNavigate } from "react-router-dom";

function Payment() {
  const navigate = useNavigate();

  const handlePaymentSubmit = (event) => {
    event.preventDefault();
    alert("Payment confirmed!");
    navigate("/confirmation"); // Navigate to a confirmation page after payment
  };

  return (
    <Container id="paymentContainer" className="mt-5">
      <Row id="paymentHeaderRow">
        <Col>
          <h2 id="paymentPageTitle" className="text-center">
            Payment Page
          </h2>
        </Col>
      </Row>
      <Row id="qrCodeRow" className="my-4 justify-content-center" >
        <Col xs={12} md={5} className="text-center">

        <img src={Qrcode}   id="qrCodeImage"  alt ="QR Code" fluid
            rounded/>

          <p id="qrCodeInstruction" className="mt-2">
            Scan the QR code above to make the payment.
          </p>
        </Col>
      </Row>
      <Row id="paymentFormRow">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <Form id="paymentForm" onSubmit={handlePaymentSubmit}>
            <Form.Group id="transactionIDGroup" className="mb-3">
              <Form.Label htmlFor="transactionIDInput">Transaction ID</Form.Label>
              <Form.Control
                id="transactionIDInput"
                type="text"
                placeholder="Enter your transaction ID"
                required
              />
            </Form.Group>
            <Form.Group id="paymentMethodGroup" className="mb-3">
              <Form.Label htmlFor="paymentMethodSelect">Payment Method</Form.Label>
              <Form.Select id="paymentMethodSelect" required>
                <option value="">Select payment method</option>
                <option value="UPI">UPI</option>
                <option value="NetBanking">Net Banking</option>
                <option value="Card">Credit/Debit Card</option>
                <option value="Wallet">Wallet</option>
              </Form.Select>
            </Form.Group>
            <Button id="paymentSubmitButton" variant="success" type="submit" className="w-100">
              Confirm Payment
            </Button>
          </Form>
        </Col>
      </Row>
      <Row id="backButtonRow" className="mt-4">
        <Col className="text-center">
          <Button
            id="backToHomeButton"
            variant="secondary"
            onClick={() => navigate("/home")}
          >
            Back to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Payment;
