import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import "./login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const { setIsLoggedIn } = useContext(AuthContext);
  let navigate = useNavigate();

  const handleAdmin = async () => {
    // Validate input fields
    if (!username || !password) {
      alert("Username and password are required!");
      return;
    }

    setLoading(true); // Set loading to true
    try {
      await axios.post(
        "http://localhost:5000/adminlog",
        { username, password },
        { withCredentials: true } // Include credentials in the request to set the cookie
      );

      console.log("Login successful, cookie should be set");

      // No need to store the token in localStorage as it's stored in cookies
      setIsLoggedIn(true);
      alert("Login successful");
      navigate("/vediospg");
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      alert(error.response?.data?.message || "Invalid username or password!");
      setIsLoggedIn(false);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <Container id="mainLogin" fluid>
      <Row>
        <Col xs={10} md={6} id="main">
          <Form id="form1">
            <Row>
              <Col xs={10} md={12} id="first">
                <Form.Label className="lable">
                  <h2 style={{ color: "white" }}> Login</h2>
                </Form.Label>
              </Col>
            </Row>

            <Form.Group className="mb-6" controlId="formGroupEmail">
              <Form.Label style={{ fontSize: "18px", color: "#fff" }}>Name</Form.Label>
              <Form.Control
                type="text"
                className="input11"
                id="input11"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label style={{ fontSize: "18px", color: "#fff" }}>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                id="input11"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col xs={10} md={12}>
                <div id="buton">
                  <Button id="submit" onClick={handleAdmin} disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={10} md={6} id="main2">
          <div id="signupdiv">
            <h4 className="signup">New User</h4>
            <br />
            <h6 className="daccnt">Don't have a Account ?</h6>
            <br />
            <button id="signupbtn">SIGN UP</button>
          </div>


        </Col>

        <Col xs={1} md={4}></Col>
      </Row>
    </Container>
  );
};

export default Login;
