import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./form.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Admission() {
  const [firstname, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [address, setAddress] = useState("");
  const [caddress, setCaddress] = useState("");
  const [collage, setCollage] = useState("");
  const [grade, setGrade] = useState("");
  const [stream, setStream] = useState("");
  const [university, setUniversity] = useState("");
  const [record, setRecord] = useState("");
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [foccupation, setFccupation] = useState("");
  const [moccupation, setMccupation] = useState("");
  const [declaration, setDeclaration] = useState("");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);


  let navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      await axios.post("http://localhost:5000/form", {
        firstname,
        contact,
        email,
        aadhaar,
        address,
        caddress,
        collage,
        grade,
        stream,
        university,
        record,
        father,
        mother,
        foccupation,
        moccupation,
        declaration,
      });
      alert("user created successfully");
      setShowPaymentOptions(true); // Show payment options modal
      
    } catch (error) {
      console.error(error);
      alert("Error creating user.");
    }
  };

  const handleNavigate = (destination) => {
    setShowPaymentOptions(false);
    if (destination === "payment") {
      navigate("/payment"); // Navigate to the payment page
    } else if (destination === "home") {
      navigate("/home"); // Navigate to the home page
    }
  };


  return (
    <>
      <Container className="main" fluid>
        <Row>
          <Col>
            <h1 style={{color:"rgb(45, 170, 16)"}}>Admission Form</h1>
          </Col>
        </Row>
        <br></br>
        <Row id="details">
          <Col>
            <Form>
              <Row id="black">
                <Col>
                  <h4 style={{textAlign:"left"}}>Student Information</h4>
                </Col>
              </Row>
              <Row>
                    <Col sm={12} md={6}>
                  <Form.Control
                    placeholder="First name"
                    className="input0"
                    id="input0"
                    value={firstname}
                    onChange={(e) => setName(e.target.value)}
                  />
                    </Col>
                    <Col sm={12} md={6}>
                    
                    <Form.Control type="date" className="input0" id="input0"/>
                    </Col>
              </Row>

              <Row>
                <Col sm={12} md={6}>
                  <Form.Control
                    placeholder="Contact Number"
                    className="input0"
                    id="input0"

                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Control
                    placeholder=" Email "
                    className="input0"
                    id="input0"

                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Control
                    placeholder=" Aadhaar Number"
                    className="input0"
                    id="input0"

                    value={aadhaar}
                    onChange={(e) => setAadhaar(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Select className="input0" id="input0">
                    <option>Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="Parmanent Address"
                    className="input1"
                    id="input0"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="Correspondence Address (if different) "
                    className="input1"
                    id="input0"
                    value={caddress}
                    onChange={(e) => setCaddress(e.target.value)}
                  />
                </Col>
              </Row>

              <Row id="black">
                <Col>
                  <h4 style={{textAlign:"left"}}>Academic Details:</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Control
                    placeholder="Collage Name"
                    className="input0"
                    id="input0"
                    value={collage}
                    onChange={(e) => setCollage(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Control
                    placeholder=" Grade/Percentage"
                    className="input0"
                    id="input0"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Control
                    placeholder=" Stream"
                    className="input0"
                    id="input0"
                    value={stream}
                    onChange={(e) => setStream(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder="University"
                    className="input0"
                    id="input0"
                    value={university}
                    onChange={(e) => setUniversity(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder=" Academic Record"
                    className="input0"
                    id="input0"
                    value={record}
                    onChange={(e) => setRecord(e.target.value)}
                  />
                </Col>
              </Row>
              <Row id="black">
                <Col>
                  <h4 style={{textAlign:"left"}}>Course Details</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Select className="input0" id="input0">
                    <option>Select Course</option>
                    <option value="1">Python</option>
                    <option value="2">Full Stack MERN Development</option>
                    <option value="3">Java</option>
                    <option value="4">DevOps</option>
                    <option value="5">Data Science</option>
                    <option value="6">Data Analytics</option>
                    <option value="7">Bussiness Analytics</option>
                    <option value="8">Saleforce</option>
                    <option value="9">C&C++</option>
                    <option value="10">UI/UX Development</option>
                    <option value="11">Dot-Net</option>
                  </Form.Select>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Select className="input0" id="input0">
                    <option>Batch Timing Preference</option>
                    <option value="1">Morning</option>
                    <option value="2">AfterNoon</option>
                    <option value="3">Evening</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row id="black">
                <Col>
                  <h4 style={{textAlign:"left"}}>Guardian Details</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder="Father's name"
                    className="input0"
                    id="input0"
                    value={father}
                    onChange={(e) => setFather(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder=" Mother's name"
                    className="input0"
                    id="input0"
                    value={mother}
                    onChange={(e) => setMother(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder="Father's Occupation"
                    className="input0"
                    id="input0"
                    value={foccupation}
                    onChange={(e) => setFccupation(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    placeholder="Mother's Occupation"
                    className="input0"
                    id="input0"
                    value={moccupation}
                    onChange={(e) => setMccupation(e.target.value)}
                  />
                </Col>
              </Row>
              <Row id="black">
                <Col>
                  <h4 style={{textAlign:"left"}}>Declaration & Signature</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="Declaration  Statement"
                    className="input2"
                    id="input0"
                    value={declaration}
                    onChange={(e) => setDeclaration(e.target.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={10}>
                  <Button id="btn11" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* payment section */}

      <Modal
        id="paymentModal"
        show={showPaymentOptions}
        onHide={() => setShowPaymentOptions(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                id="onlinePayment"
                variant="success"
                className="w-100"
                onClick={() => handleNavigate("payment")}
              >
                Online
              </Button>
            </Col>
            <Col>
              <Button
                id="offlinePayment"
                variant="info"
                className="w-100"
                onClick={() => handleNavigate("home")}
              >
                Offline
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

    </>
  );
}
export default Admission;

// import Col from "react-bootstrap/Col";
/*
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./form.css";

function Admission() {
  const [firstname, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [address, setAddress] = useState("");
  const [caddress, setCaddress] = useState("");
  const [collage, setCollage] = useState("");
  const [grade, setGrade] = useState("");
  const [stream, setStream] = useState("");
  const [university, setUniversity] = useState("");
  const [record, setRecord] = useState("");
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [foccupation, setFccupation] = useState("");
  const [moccupation, setMccupation] = useState("");
  const [declaration, setDeclaration] = useState("");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await axios.post("http://localhost:5000/form", {
        firstname,
        contact,
        email,
        aadhaar,
        address,
        caddress,
        collage,
        grade,
        stream,
        university,
        record,
        father,
        mother,
        foccupation,
        moccupation,
        declaration,
      });
      alert("User created successfully");
      setShowPaymentOptions(true); // Show payment options modal
    } catch (error) {
      console.error(error);
      alert("Error creating user.");
    }
  };

  const handleNavigate = (destination) => {
    setShowPaymentOptions(false);
    if (destination === "payment") {
      navigate("/payment"); // Navigate to the payment page
    } else if (destination === "home") {
      navigate("/home"); // Navigate to the home page
    }
  };

  return (
    <>
      <Container className="main">
        <Row>
          <Col>
            <h1>Admission Form</h1>
          </Col>
        </Row>
        <br />
        <Row id="details">
          <Col>
            <Form>
              <Row id="black">
                <Col>
                  <h4>Student Information</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Control
                    id="firstname"
                    placeholder="First name"
                    className="input0"
                    value={firstname}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Control id="dob" type="date" className="input0" />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Control
                    id="contactNumber"
                    placeholder="Contact Number"
                    className="input0"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Control
                    id="email"
                    placeholder="Email"
                    className="input0"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Control
                    id="aadhaar"
                    placeholder="Aadhaar Number"
                    className="input0"
                    value={aadhaar}
                    onChange={(e) => setAadhaar(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Select id="gender" className="input0">
                    <option>Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    id="permanentAddress"
                    placeholder="Permanent Address"
                    className="input1"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    id="correspondenceAddress"
                    placeholder="Correspondence Address (if different)"
                    className="input1"
                    value={caddress}
                    onChange={(e) => setCaddress(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control
                    id="fathersName"
                    placeholder="Father's Name"
                    className="input0"
                    value={father}
                    onChange={(e) => setFather(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    id="mothersName"
                    placeholder="Mother's Name"
                    className="input0"
                    value={mother}
                    onChange={(e) => setMother(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    id="declaration"
                    placeholder="Declaration Statement"
                    className="input2"
                    value={declaration}
                    onChange={(e) => setDeclaration(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={10}>
                  <Button id="btn11" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal
        id="paymentModal"
        show={showPaymentOptions}
        onHide={() => setShowPaymentOptions(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                id="onlinePayment"
                variant="success"
                className="w-100"
                onClick={() => handleNavigate("payment")}
              >
                Online
              </Button>
            </Col>
            <Col>
              <Button
                id="offlinePayment"
                variant="info"
                className="w-100"
                onClick={() => handleNavigate("home")}
              >
                Offline
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Admission;
*/