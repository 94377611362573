import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import web from "./webkit_info.jpg";
import "./vedpgcss.css";

function Webkit() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Website Development</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={web} alt="Webkit Development" />
            </Card>
          </Col>

       

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">Webkit Development</Card.Title>
              <br></br>
              <h8>Amplemind</h8> provides
              professional training in{" "}
              <h8>Website Development.</h8> <br></br>   
              In today's digital era, a strong online presence is essential for business success. At AmpleMind we specialize in creating high-performance, visually stunning, and user-friendly websites that help businesses grow.
              <br />
              <p style={{textAlign:"left", color:"black"}}>✅ Custom Website Development – Tailored solutions that match your business needs and goals.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ E-commerce Development – Build powerful online stores with seamless shopping experiences.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Responsive Web Design – Mobile-friendly and fully optimized websites for all devices.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ CMS Development – Easy-to-manage content with WordPress, Shopify, and other platforms.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ SEO Optimization – Improve rankings and drive organic traffic to your site.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Web Application Development – Scalable and secure applications to enhance business operations.</p>
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Webkit;
