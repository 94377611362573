import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./star.css";
import pic from "./girls.jpg";
import { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
function Staragile() {
  // const [name, setname] = useState("");
  // const [middleName, setMiddlename] = useState("");
  // const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();
  function HandleLogin() {
    navigate("/adminlog");
  }


  const HandleSignUp = async () => {
    try {
      await axios.post("http://localhost:5000/signup", {
        email,
        username,
        password,
      });
      alert("Sign Up Success");
      navigate("/adminlog");
    } catch (error) {
      console.error(error);
      alert("Error signing up. Please try again.");
    }
  };

  return (
    <div className="signup-container">
      <Container>
        <Row>
          <Col md={6} className="signup-image">
            <img src={pic} className="img" alt="imgs" />
          </Col>
          <Col md={6} className="signup-form">
            <h1>Register Now </h1>
            <Form>
              <Form.Group controlId="formFullName" className="input">
                {/* <Form.Label>Full Name</Form.Label> */}
                {/* <Form.Control type="text" placeholder="Name..."  /> */}
              </Form.Group>

              <Form.Group
                controlId="formEmail"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              >
                {/* <Form.Label>Email</Form.Label> */}

                <Form.Control type="email" placeholder="Email address..." />
              </Form.Group>

              <Form.Group
                controlId="formUsername"
                className="input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              >
                {/* <Form.Label>Username</Form.Label> */}
                <Form.Control type="text" placeholder="Username..." />
              </Form.Group>

              <Form.Group
                controlId="formPassword"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              >
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control type="password" placeholder="Password..." />
              </Form.Group>

              <Form.Group controlId="formRepeatPassword" className="input">
                {/* <Form.Label>Conform Password</Form.Label> */}
                <Form.Control
                  type="password"
                  placeholder="Confirm Password..."
                />
              </Form.Group>

              <Form.Group controlId="formTerms" className="input">
                <Form.Check
                  type="checkbox"
                  label="I agree to the Terms of Use"
                />
              </Form.Group>
              <div id="butn">
                <Button variant="primary" type="submit" className="signup-btn" onClick={HandleSignUp}>
                  Sign Up
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Staragile;
