import logo from './logo.svg';
import './App.css';
import Routing from './Routing';
import FreeTraining from './Home';
import Navigation from './Navbar';
import AuthProvider from "./AuthContext";



function App() {
  return (
    <AuthProvider>
      <Routing />
      </AuthProvider> 
      
  )
}

export default App;
