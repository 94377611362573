import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import elec from "./electric.jpg";

import "./vedpgcss.css";

function Electric() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Electrical Designing</h1>
      </div>
      <Container id="contain">
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={elec} alt="Electrical Designing" />
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">Electrical Designing</Card.Title>
              <br></br>
              <h8>Amplemind</h8> offers specialized training in <br></br>
              <h8>' Electrical Designing.'</h8>
              <br></br>
              Our comprehensive program covers fundamental principles, advanced
              techniques, and practical applications to ensure students excel in
              designing electrical systems. With a focus on safety, efficiency,
              and innovation, our experienced instructors guide students through
              circuit design, power distribution, and system integration.
              Amplemind emphasizes hands-on learning with real-world projects
              and industry insights. Whether you're a beginner or looking to
              advance your skills, Amplemind equips you with the knowledge and
              tools needed to succeed in Electrical Designing. Join us to
              illuminate your future in electrical design.
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Electric;
