import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import company from "./companyBill.jpg";
import restraunt from "./restroBill.jpg";
import appdev from "./appDev.jpg";
import "./vedpgcss.css";

function Software() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Software Development</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={company} alt="Company Billing System" />
            </Card>
          </Col>

    

          <Col xs={12} md={5}>
            <Card.Text id="info">
              <Card.Title id="titles">Company Billing System</Card.Title>
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Automated Invoicing – Generate and send invoices instantly with customizable templates.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Expense & Revenue Tracking – Keep track of all transactions and financial records in real-time.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Multi-Payment Integration – Accept payments via credit/debit cards, bank transfers, and digital wallets.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Tax & Compliance Management – Ensure accurate tax calculations and compliance with local regulations.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Recurring Billing – Automate subscription-based payments with ease.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ User Access Control – Manage roles and permissions for secure financial operations.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Comprehensive Reporting – Generate detailed reports for better financial decision-making.</p>
            </Card.Text>
          </Col>
        </Row>

        <Row className="rows">
          

          <Col xs={12} md={5}>
            <Card.Text id="info">
              <Card.Title id="titles">Restraunt / Cafe Billing System</Card.Title>
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Fast & Easy Billing – Generate accurate bills instantly with tax and discount options.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Table & Order Management – Efficiently manage dine-in, takeaway, and online orders.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ POS Integration – Compatible with touch-screen POS systems for quick transactions.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Multiple Payment Methods – Accept cash, credit/debit cards, digital wallets, and UPI.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Inventory & Stock Management – Track ingredient usage and reduce wastage.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Staff & Role Management – Assign roles and control access for smooth operations.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Comprehensive Sales Reports – Gain insights with daily, weekly, and monthly reports.</p>
            </Card.Text>
          </Col>

          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={restraunt} alt="Restraunt / Cafe Billing system" />
            </Card>
          </Col>
        </Row>

        {/* Third row */}

        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={appdev} alt="App Development" />
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={5}>
            <Card.Text id="info">
              <Card.Title id="titles">App Development</Card.Title>
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Custom Mobile App Development – Fully tailored apps designed to meet your unique business goals.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ iOS & Android Development – Native app solutions for a seamless experience on both platforms.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Cross-Platform Development – Cost-effective apps with React Native, Flutter, and other technologies.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ UI/UX Design – Intuitive and visually appealing designs for the best user experience.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ App Integration & API Development – Connect your app with third-party services and existing systems.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ E-commerce & Business Apps – Scalable solutions to boost sales and customer engagement.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ App Maintenance & Support – Ongoing updates, bug fixes, and performance optimization.</p>
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Software;
