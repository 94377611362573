// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Logo from "./images/logo.png";
// import "./Navbar.css";


// function Navigation() {
//   return (
//     <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
//       <Container>
//         <img className="logo" src={Logo} alt="Logo" />
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link  href="/home" >Home</Nav.Link>
//             <Nav.Link  href="/services" >Services</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default Navigation;




import "./Navbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Logo from "./images/logo.png";


function Navigation(){
  return (
    <>
    <Navbar expand="lg" fixed="top" collapseOnSelect id="scrollnav">
      <Container fluid >
        <Navbar.Brand className="logo" to="/">
          <img className="logo" src={Logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle id="scrollnav" className="navMainn" style={{width:"50px", maxHeight: "50px",backgroundColor:"white",color:"white"}}/>
        <Navbar.Collapse id="navbarScroll responsive-navbar-nav" className="Navdiv">
          <Nav
            className="me-auto my-2 my-lg-0" 
            style={{ maxHeight: "50px",backgroundColor:"red",color:"yellow"}}
            navbarScroll
          />
          <Nav.Link  className="nav-tab " href="/home" >
            Home
          </Nav.Link>
          <Nav.Link  className="nav-tab" href="/services">
            Services
          </Nav.Link>
          <Nav.Link  className="nav-tab" href="/courses">
            Courses
          </Nav.Link>
          <Nav.Link className="nav-tab" href="/about">
            About
          </Nav.Link>
          <Nav.Link  className="nav-tab" href="/contact">
            Contact
          </Nav.Link>
          <Nav.Link  className="nav-tab" href="/form">
            Admission
          </Nav.Link>
          <Nav.Link className="nav-tab" href="/adminlog">
            Login
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Navigation;
