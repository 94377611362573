import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import loggo from './images/amplelogo.png'
import './Footerr.css'
import { Link } from "react-router-dom";


function Footerr() {
    return (
        <>  <Container fluid id='bgfootbanner' >
           
          
            </Container>
            <Container fluid id='footMainn'>
                
                <Row className="justify-content-md-center">

                    <Col sm>
                        <img src={loggo} id='footlogo'/>
                        <p id='Footpara'>
                            Amplemind is an empowering future tech leaders with cutting-edge courses and services of software products and digital marketing
                            Join us to innovate, learn, and excel in the digital world.
                        </p>

                        <h3>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faFacebook} /></a></span>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faWhatsapp} /></a></span>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faInstagram} /></a></span>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faLinkedin} /></a></span>
                        </h3>

                    </Col>
                    <Col sm>
                        <div>
                            <h2>Courses</h2>
                            <li><Link as={Link} to="" id='footlink'>Full Stack MERN</Link></li>
                            <li><Link as={Link} to="/java" id='footlink'>Full Stack Java</Link></li>
                            <li><Link as={Link} to="/Mernstack" id='footlink'>Full Stack .Net</Link></li>
                            <li><Link as={Link} to="/python" id='footlink'>Python</Link></li>
                            <li><Link as={Link} to="/Datascience" id='footlink'>Data Science</Link></li>
                            <li><Link as={Link} to="/Dataanalytics" id='footlink'>Data Analytics</Link></li>
                            <li><Link as={Link} to="/courses" id='footlink'>Business Analytics</Link></li>
                            <li><Link as={Link} to="/Devops" id='footlink'>DevOps</Link></li>

                        </div>
                    </Col>
                    <Col sm>
                        <h2>Quick Links</h2>
                        <li><Link as={Link} to="/home" id='footlink'>Home</Link></li>
                        <li><Link as={Link} to="/services" id='footlink'>Services</Link></li>
                        <li><Link as={Link} to="/courses" id='footlink'>Courses</Link></li>
                        <li><Link as={Link} to="/about" id='footlink'>About</Link></li>
                        <li><Link as={Link} to="" id='footlink'>Admission</Link></li>
                        <li><Link as={Link} to="" id='footlink'>Student Login</Link></li>
               

                    </Col>
                    <Col sm>

                        <h2>Services</h2>
                        <li><Link as={Link} to="/webkit" id='footlink'>Website Development</Link></li>
                        <li><Link as={Link} to="/bills" id='footlink'>Software Development</Link></li>
                        <li><Link as={Link} to="/elec" id='footlink'>Electrical Disign</Link></li>
                        <li><Link as={Link} to="/interior" id='footlink'>Interior Design</Link></li>
                        <li><Link as={Link} to="/architect" id='footlink'>Architech Design</Link></li>


                    </Col>
                    <Col>
                        <h2>Enquiry</h2>
                        <form>
                            <input type='text' id='Footemailentry' placeholder='Your Message'/><br /><br />
                            <input type='text' id='Footemailentry' placeholder='Your Contact'/><br /><br />
                            <input type='submit'id='Footemailbtn'/>
                        </form>
                        

                    </Col>

                </Row>
                <Row>
                    <p id='rights'>© 2025. All Rights Reserved. Design by AMPLEMIND</p>
                </Row>
            </Container>


        </>
    )
}

export default Footerr;
