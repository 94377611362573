import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import resarch from "./resarch.jpg";
import comarch from "./comdesgn.jpeg";
import "./vedpgcss.css";

function Architect() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Architectural Designing</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={resarch} alt="Resenditial Architectural Designing" />
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">
                Resenditial Architectural Designing
              </Card.Title>
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Residential Architecture – Thoughtfully designed homes that reflect your lifestyle and personality.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Commercial & Office Architecture – Smart, functional, and aesthetically appealing business spaces.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Industrial Architecture – Efficient and well-planned structures tailored to industrial needs.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Landscape Architecture – Creating beautiful outdoor spaces that enhance the environment.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Sustainable & Green Architecture – Eco-friendly designs with energy-efficient solutions.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Urban Planning & Development – Comprehensive designs for smart and sustainable cities.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ 3D Modeling & Visualization – Get a realistic preview of your project before execution.</p>
            </Card.Text>
          </Col>
        </Row>
        {/* Second Row */}
        <Row className="rows">
          <div id="anyy"></div>
          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">
                Commercial Architectural Designing
              </Card.Title>
            
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Residential Architecture – Thoughtfully designed homes that reflect your lifestyle and personality.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Commercial & Office Architecture – Smart, functional, and aesthetically appealing business spaces.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Industrial Architecture – Efficient and well-planned structures tailored to industrial needs.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Landscape Architecture – Creating beautiful outdoor spaces that enhance the environment.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Sustainable & Green Architecture – Eco-friendly designs with energy-efficient solutions.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Urban Planning & Development – Comprehensive designs for smart and sustainable cities.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ 3D Modeling & Visualization – Get a realistic preview of your project before execution.</p>
            </Card.Text>
          </Col>

          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={comarch} alt="Commercial Architectural Designing" />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Architect;
