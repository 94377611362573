import React, { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";

import {
  Container,
  Row,
  Col,
  Nav,
  Card,
  Modal,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./adminpage.css";
import sample from "../images/netflix.png";

import InstituteImg from "../images/amplelogo.png";
import axios from "axios";

const AdminPage = () => {

  ////////////////////////////////////////////////////////////////////////////
  const [Empname, setEmpname] = useState("");
  const [EmpPost, setEmpPost] = useState("");
  const [Empdate, setEmpdate] = useState("");
  const [EmpSal, setEmpSal] = useState("");
  const [Empleave, setEmpleave] = useState("");
  const [Empimg, setEmpimg] = useState(null); // Change to handle file uploads

  // Post Employee details including the image ///////////////////////////////
  const handleEmployee = async () => {
    try {
      const formData = new FormData(); // FormData is used to handle files and other data
      formData.append("Empname", Empname);
      formData.append("EmpPost", EmpPost);
      formData.append("Empdate", Empdate);
      formData.append("EmpSal", EmpSal);
      formData.append("Empleave", Empleave);
      formData.append("Empimg", Empimg);

      await axios.post("http://localhost:5000/employeeEdit", formData, {
        headers: { "Content-Type": "multipart/form-data" }, // Set proper headers for file uploads
      });

      alert("Employee added successfully!");
      setEmpname("");
      setEmpPost("");
      setEmpdate("");
      setEmpSal("");
      setEmpleave("");
      setEmpimg(null); // Reset the image input
    } catch (error) {
      console.error(error);
      alert("Error posting product. Please try again.");
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////

  const [Coursename, setCoursename] = useState("");
  const [Coursedes, setCoursedes] = useState("");
  const [Coursedue, setCoursedue] = useState("");
  const [Courseimg, setCourseimg] = useState(null); // Change to handle file uploads
////////////////////////////////////////////////////////////////////////////////////////

  // Post Course details including the image ///////////////////////////////
  const handleCourse = async () => {
    try {
      const courseData = new FormData(); // FormData is used to handle files and other data
      courseData.append("Coursename", Coursename);
      courseData.append("Coursedes", Coursedes);
      courseData.append("Coursedue", Coursedue);
      courseData.append("Courseimg", Courseimg);

      await axios.post("http://localhost:5000/courseEdit", courseData, {
        headers: { "Content-Type": "multipart/form-data" }, // Set proper headers for file uploads
      });

      alert("Course added successfully!");
      setCoursename("");
      setCoursedes("");
      setCoursedue("");
      
      setCourseimg(null); // Reset the image input
    } catch (error) {
      console.error(error);
      alert("Error posting course. Please try again.");
    }
  };

/////////////////////////////////////////////////////////////////////////////

// modal showing code
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [student, setStudent] = useState(false);
  const handleStuClose = () => setStudent(false);
  const handleStuShow = () => setStudent(true);

  const [showEmp, setEmpShow] = useState(false);
  const handleEmpClose = () => setEmpShow(false);
  const handleEmpShow = () => setEmpShow(true);

  return (
    <Container fluid className="admin-container">
      <Row>
        {/* Sidebar Navigation */}
        <Col md={3} className="sidebar">
          <h4 className="sidebar-title">Admin Panel</h4>
          <Nav className="flex-column">
            <Nav.Link
              href="#add-course"
              id="nav-add-course"
              onClick={handleShow}
            >
              Add Course
            </Nav.Link>
            <Nav.Link
              href="#students"
              onClick={handleStuShow}
              id="nav-students"
            >
              Students
            </Nav.Link>
            <Nav.Link href="#courses" id="nav-courses">
              Courses
            </Nav.Link>
            <Nav.Link href="#projects" id="nav-projects">
              Projects
            </Nav.Link>
            <DropdownButton id="dropEmp" title="Employee">
              <Nav.Link
                href="#employees"
                onClick={handleEmpShow}
                id="nav-employees"
              >
                Create 
              </Nav.Link>

              <Nav.Link
                href="/employlist"
                id="nav-employees"
              >
                View List
              </Nav.Link>
            </DropdownButton>
          </Nav>
        </Col>

        {/* Main Content */}
        <Col md={9} className="main-content">
          <Card className="info-card">
            <Card.Body>
              <div className="info-section">
                <img
                  src={InstituteImg}
                  alt="Institute Logo"
                  className="institute-logo"
                  id="institute-logo"
                />
                <div className="admin-info">
                  <h3 id="institute-name">Institute Name</h3>
                  <p id="institute-desc">
                    Welcome to the admin panel. Manage all aspects of the
                    institute from here.
                  </p>
                </div>
                <img
                  src={sample}
                  alt="Admin"
                  className="admin-image"
                  id="admin-image"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add Course Modal */}

      <Modal show={show} onHide={handleClose} className="add-course-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="courseName">
              <Form.Label>Course Name</Form.Label>
              <Form.Control type="text" placeholder="Enter course name" />
            </Form.Group>
            <Form.Group controlId="courseDescription" className="mt-3">
              <Form.Label>Course Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter course description"
              />
            </Form.Group>
            <Form.Group controlId="courseDuration" className="mt-3">
              <Form.Label>Course Duration</Form.Label>
              <Form.Control type="text" placeholder="Enter course duration" />
            </Form.Group>
            <Form.Group controlId="courseImage" className="mt-3">
              <Form.Label>Course Image</Form.Label>
              <Form.Control type="file" onChange={(e) => setCourseimg(e.target.files[0])} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCourse}>Save Course</Button>
        </Modal.Footer>
      </Modal>

      {/* Students info model code */}

      <Modal
        show={student}
        onHide={handleStuClose}
        className="student-details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Student Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="studentName">
              <Form.Label>Student Name</Form.Label>
              <Form.Control type="text" placeholder="Enter student name" />
            </Form.Group>
            <Form.Group controlId="courseEnrolled" className="mt-3">
              <Form.Label>Course Enrolled</Form.Label>
              <Form.Control type="text" placeholder="Enter course name" />
            </Form.Group>
            <Form.Group controlId="joiningDate" className="mt-3">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="feesPaid" className="mt-3">
              <Form.Label>Fees Paid</Form.Label>
              <Form.Control type="number" placeholder="Enter fees paid" />
            </Form.Group>
            <Form.Group controlId="remainingPayment" className="mt-3">
              <Form.Label>Remaining Payment</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter remaining amount"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleStuClose}>
            Close
          </Button>
          <Button variant="primary">Save Details</Button>
        </Modal.Footer>
      </Modal>

      {/* Employees info model code */}

      <Modal
        show={showEmp}
        onHide={handleEmpClose}
        className="employee-details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="employeeName">
              <Form.Label>Employee Name</Form.Label>
              <Form.Control
                type="text"
                value={Empname}
                onChange={(e) => setEmpname(e.target.value)}
                placeholder="Enter employee name"
              />
            </Form.Group>
            <Form.Group controlId="position" className="mt-3">
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                value={EmpPost}
                onChange={(e) => setEmpPost(e.target.value)}
                placeholder="Enter employee's position"
              />
            </Form.Group>
            <Form.Group controlId="startDate" className="mt-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                value={Empdate}
                onChange={(e) => setEmpdate(e.target.value)}
                type="date"
              />
            </Form.Group>
            <Form.Group controlId="salary" className="mt-3">
              <Form.Label>Salary</Form.Label>
              <Form.Control
                type="number"
                value={EmpSal}
                onChange={(e) => setEmpSal(e.target.value)}
                placeholder="Enter employee's salary"
              />
            </Form.Group>
            <Form.Group controlId="remainingLeave" className="mt-3">
              <Form.Label>Remaining Leave</Form.Label>
              <Form.Control
                type="number"
                value={Empleave}
                onChange={(e) => setEmpleave(e.target.value)}
                placeholder="Enter remaining leave days"
              />
            </Form.Group>


            <Form.Group controlId="courseImage" className="mt-3">
              <Form.Label>Employee Image</Form.Label>
              <Form.Control type="file"  onChange={(e) => setEmpimg(e.target.files[0])} />
            </Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEmpClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEmployee}>
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default AdminPage;
