import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Make a request to check if the token is valid by verifying it server-side
        const res = await fetch("http://localhost:5000/validateToken", {
          method: "POST",
          credentials: "include", // Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.ok) {
          setIsLoggedIn(true); // If token is valid, user is logged in
        } else {
          setIsLoggedIn(false); // If the response indicates the token is invalid, log out the user
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setIsLoggedIn(false); // On error, assume user is not logged in
      }
    };



    // Check auth status when the app starts
    checkAuthStatus();


        // Optionally, set an interval to check token status periodically
        const tokenCheckInterval = setInterval(checkAuthStatus, 5 * 60 * 1000); // Check every 5 minutes

        return () => clearInterval(tokenCheckInterval); // Clean up the interval on component unmount
     

  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
