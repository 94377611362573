// import img1 from '../../public/img/courses images/python.png';
// import img1 from './python.jpeg';
import python from './course_img/python.png';
import mern from './course_img/MERN.png';
import java from './course_img/java.png';
import datascience from './course_img/data_science.png';
import dataanalytics from './course_img/data_analytics.png';
import businessanalytics from './course_img/business_analytics.png';
import devops from './course_img/devops.png';
import salesforce from './course_img/salesforce.png';
import c from './course_img/c_and_c++.png';
import civil from './course_img/civil.jpg';
import electrical from './course_img/electrical.jpg';
import mechanical from './course_img/mechanical.jpg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Course.css';
import { Link } from 'react-router-dom';
import "aos/dist/aos.css";
import AOS from 'aos';
import { useEffect } from 'react';




function Course() {
    useEffect(() => {
        AOS.init({duration:"1000"})
    })
    return (
        <>
        <Container className='coursecontainer' fluid>
            {/* Ist Section of Courses */}
            <Row>
                {/* <h1 className='coursehead'>We Offer IT Courses</h1> */}
                <Col data-aos="zoom-in" >
                    <Card style={{ width: '20rem'}} className='coursecard' >
                        <a href=''>
                            <Link to="/python"><Card.Img src={python} className='courseinsidecard' data-aos="zoom-in" data-aos-delay="200" /></Link>
                            <Link to="/python"><Button className='coursebton' variant="primary">PYTHON</Button></Link>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                        <Link to="/Mernstack"> <Card.Img src={mern} className='courseinsidecard' /></Link>
                        <Link to="/Mernstack"><Button className='coursebton' variant="primary">FULL STACK DEVELOPMENT</Button></Link>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Link to="/java"><Card.Img src={java} className='courseinsidecard' /></Link>
                            <Link to="/java"><Button className='coursebton' variant="primary">JAVA</Button></Link>
                        </a>
                    </Card>
                </Col>
            </Row>


            {/* Second Part */}
            <Row>
                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Link to="/Devops"><Card.Img src={devops} className='courseinsidecard' /></Link>
                            <Link to="/Devops"><Button className='coursebton' variant="primary">DevOps</Button></Link>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Link to="/Datascience"><Card.Img src={datascience} className='courseinsidecard' /></Link>
                            <Link to="/Datascience"><Button className='coursebton' variant="primary">Data Science</Button></Link>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Link to="/Dataanalytics"><Card.Img src={dataanalytics} className='courseinsidecard' /></Link>
                            <Link to="/Dataanalytics"><Button className='coursebton' variant="primary"> Data Analytics </Button></Link>
                        </a>
                    </Card>
                </Col>
            </Row>

            {/* Section 3 */}

            <Row>
                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={businessanalytics} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">Business Analytics</Button>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={salesforce} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">Salesforce</Button>
                        </a>
                    </Card>
                </Col>

                <Col data-aos="zoom-in">
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={c} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">C & C++</Button>
                        </a>
                    </Card>
                </Col>
            </Row>

            {/* section for Non-IT Courses */}
            
            {/* <Row>
                <h1 className='coursehead'>Non-IT Courses</h1>
                <Col>
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={civil} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">Civil CADD</Button>
                        </a>
                    </Card>
                </Col>

                <Col>
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={electrical} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">Electrical CADD</Button>
                        </a>
                    </Card>
                </Col>

                <Col>
                    <Card style={{ width: '20rem' }} className='coursecard'>
                        <a href=''>
                            <Card.Img src={mechanical} className='courseinsidecard' />
                            <Button className='coursebton' variant="primary">Mechanical CADD</Button>
                        </a>
                    </Card>
                </Col>
            </Row>
             */}
        </Container>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg> */}
        </>

        
    );
}

export default Course;