import { useEffect, useState } from "react";
import axios from "axios";
import "./employees.css"

function Employelist() {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await axios.get("http://localhost:5000/employeeEdit");
      setDetails(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
    <div id="emppg">
      <h1>This is the page where you can see the list of employees.</h1>

      <ul>
        {details.map((employee) => (
          <li><p>{employee.Empname}</p> {/* Ensure '_id' is used as key */}
            <p>{employee.EmpPost}</p> 
            <p>{employee.Empdate}</p> {/* Format Date */}
            <p>{employee.EmpSal}</p>
            <p>{employee.Empleave}</p>
            <img
              src={`data:image/jpeg;base64,${employee.Empimg}`}
              alt={employee.Empname}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
          </li>
        ))}
      </ul>
      </div>
    </>
  );
}

export default Employelist;
