import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ved from "./sample.mp4";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
  
import "./vedpgcss.css";

function Vedpg() {
  // const { isLoggedIn } = useContext(AuthContext);
  
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) {
  //     return <Navigate to="/adminlog" replace />;
  //   }
  return (
    <>
      <div>
        <h1 id="h1">Vedio Page</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={6}  className="cols">
            {" "}
            <Card id="card"> 
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <video width="100%" controls src={ved} muted>
                {" "}
              </video>
              {/* <Card.Body>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body> */}
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={5} className="cols">
            <Card.Text id="info">
              <Card.Title id="titles">Python</Card.Title>
              <br></br>
              Python is a dynamic, interpreted (bytecode-compiled) language.
              There are no type declarations of variables, parameters,
              functions, or methods in source code. This makes the code short
              and flexible, and you lose the compile-time type checking of the
              source code.
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Vedpg;
