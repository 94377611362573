import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import "./Mernstack.css";
import python from '../course_img/python.png';

function Python() {
    return (
        <>
            <Container fluid className='mernMain'>
                <h2 id='mernh2'>Course on Python</h2>

                <Row>
                    <Col className='mern-left' md={4}>
                        <img src={python} />
                    </Col>

                    <Col className='mern-right' md={8}>

                        <p>
                        Python is a versatile programming language used in various fields due to its simplicity, flexibility, 
                        and wide range of libraries and frameworks
                        </p>
                        <ul>
                        <h5 id='mernh5'>1. Data Science and Machine Learning:</h5>
                            <li>NumPy: A library for numerical computing, providing support for large, 
                            multi-dimensional arrays and matrices</li>
                            <li>
                            Pandas: A library for data manipulation and analysis, offering data structures and operations 
                            for manipulating numerical tables and time series
                            </li>
                            <li>
                            Matplotlib and Seaborn: Libraries for data visualization.
                            </li>
                            
                        </ul>
                        <ul>
                            <h5 id='mernh5'>2. Game Development:</h5>
                            <li>Pygame: A set of Python modules designed for writing video games.</li>
                            
                        </ul>
                        <ul>
                        <h5 id='mernh5'>Desktop GUI Applications:</h5>
                            <li>Tkinter: The standard Python interface to the Tk GUI toolkit.</li>
                            <li>PyQt and PySide: Python bindings for the Qt application framework.</li>
                            
                        </ul>
                        <ul>
                        <h5 id='mernh5'>Scripting and Automation:</h5>
                            <li>Python is widely used for writing automation scripts,
                             handling file operations, and scheduling tasks.</li>
                            
                        </ul>
                        <ul>
                        <h5 id='mernh5'>Artificial Intelligence (AI) </h5>
                            <li >libraries such as Matplotlib, Seaborn, and Plotly are commonly used for creating visualizations from data.</li>
                           
                        </ul>
                        <ul>
                            <h5 id='mernh5'>Finance and Trading:</h5>
                            <li>Python is used for financial modeling, algorithmic trading, and quantitative analysis
                             due to libraries like Pandas, NumPy, and Quantlib.</li>
                           
                        </ul>
                        <ul>
                        <h5 id='mernh5' >Web Development:</h5>
                            <li>Django: A high-level web framework that encourages rapid development and clean, pragmatic design.</li>
                            <li>Flask: A lightweight WSGI web application framework, perfect for small to medium-sized web applications.</li>
                        </ul>
                        
                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        <h1 className='syslabusHead'>Python Syllabus</h1>
                        <div className='mern-accordian accordion-flush'>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Introduction to Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>What is Python programming language</li>
                                        <li>Advantages and applications of Python</li>
                                        <li>Installation of Python</li>
                                        <li>Python Keywords</li>
                                        <li>The statement, Indentation, and Comment in Python</li>
                                        <li>Rule of Variables in Python</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Input / Output</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Taking input</li>
                                        <li>Output Using print() function</li>
                                        <li>Output Formatting</li>
                                       
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Operators</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Operators in Python</li>
                                        <li>Types of operators</li>
                                        <li>Arithmetic operator</li>
                                        <li>Assignment operator</li>
                                        <li>Comparison operator</li>
                                        <li>Logical operator</li>
                                        <li>Bitwise operator</li>
                                        <li>Special operators</li>
                                        <li>Ternary Operator</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Data Types</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Python String</li>
                                        <li>Numbers</li>
                                        <li>Python String</li>
                                        <li>Python List</li>
                                        <li>Python Tuples</li>
                                        <li>Python Sets</li>
                                        <li>Python Dictionary</li>
                                        <li>Type Conversion</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Control Panel</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Python Conditional statements</li>
                                        <li>Python For Loop</li>
                                        <li>Python While Loop</li>
                                        <li>Python break statement</li>
                                        <li>Python continue statement</li>
                                        <li>Python pass statement</li>
                                      
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Strings</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Access characters/elements in a string</li>
                                        <li>Concatenation of two or more string</li>
                                        <li>String Slicing</li>
                                        <li>Iterating string</li>
                                        <li>Delete or Change String</li>
                                        <li>String Formatting</li>
                                        <li>String Methods</li>
                                        <li>Escape Characters</li>
                                    
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Lists in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Knowing the size of the list</li>
                                        <li>Adding elements to a list</li>
                                        <li>Accessing elements from the list</li>
                                        <li>Delete or Remove elements from the list</li>
                                        <li>Slicing of a list</li>
                                        <li>Iterating a list</li>
                                        <li>List Comprehension</li>
                                        <li>Nested list</li>
                                        <li>List Methods</li>
                                       
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Tuples</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Accessing elements in a Tuple</li>
                                        <li>Tuple Slicing</li>
                                        <li>Update Tuple</li>
                                        <li>Unpack Tuple</li>
                                        <li>Concatenation of Tuples</li>
                                        <li>Changing and deleting Tuples</li>
                                        <li>Python Tuple Methods</li>
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Dictionary in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Accessing Elements from a Dictionary</li>
                                        <li>Add/Change Elements in a Dictionary</li>
                                        <li>Nested Dictionary</li>
                                        <li>Deleting Elements from Dictionary</li>
                                        <li>Dictionary Methods</li>
                                      
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Sets in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Adding Elements to a Set</li>
                                        <li>Accessing a Set</li>
                                        <li>Removing Elements from a Set</li>
                                        <li>Set Methods</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Functions</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Syntax of Function</li>
                                        <li>Types of Functions</li>
                                        <li>Arguments</li>
                                        <li>Recursion Function</li>
                                        <li>Recursive Function</li>
                                        <li>Lambda functions</li>
                                        <li>Modules in python</li>
                                        <li>Packages</li>
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>OOP in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Class</li>
                                        <li>Object</li>
                                        <li>Inheritance</li>
                                        <li>Iterators</li>
                                        <li>Encapsulation</li>
                                        <li>Polymorphism</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Exception Handling in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Syntax Error</li>
                                        <li>Try and Except in Exception Handling</li>
                                        <li>Python Errors and Built-in Exceptions</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>File Handling </Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Open a File </li>
                                        <li>Close a file </li>
                                        <li>write a python </li>
                                        <li>Read Files</li>
                                        <li>Python Directory</li>
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Pickle</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to Pickle</li>
                                        <li>Storing Data with Pickles</li>
                                        <li>Pickling Files</li>
                                        <li>Unpickling FIles</li>
                                        
                                        
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            

                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Matplotlib</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to Matplotlib</li>
                                        <li>Matplotlib pyplot</li>
                                        <li>Matplotlib Markers</li>
                                        <li>Matplotlib Line</li>
                                        <li>Matplotlib Labels</li>
                                        <li>Matplotlib Grid </li>
                                        <li>Matplotlib Subplot</li>
                                        <li>Matplotlib Scatter</li>
                                        <li>Matplotlib Bars</li>
                                        <li>Matplotlib Histogram</li>
                                        <li>Matplotlib Pie Charts</li>      
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>MongoDB</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to MongoDB</li>
                                        <li>MongoDB Installation</li>
                                        <li>Create and Read Operations</li>
                                        <li>ObjectID and BSON</li>
                                        <li>CRUD Operations</li>
                                        <li>UpdateOne and UpdateMany</li>
                                        <li>DeleteOne and DeleteMany</li>
                                        <li>MongoDB Schema</li>
                                        <li>Database Modeling</li>
                                        <li>Relation in Databse MongoDB</li>
                                        <li>MongoDB connection through Mongoose</li>
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>SQL</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to SQL</li>
                                        <li>SQL Data Types</li>
                                        <li>SQL Operators</li>
                                        <li>SQL CRUD Operations</li>
                                        <li>SQL Table</li>
                                        <li>SQL Select</li>
                                        <li>SQL Clause</li> 
                                        <li>SQL Order BY</li>
                                        <li>SQL Insert</li>
                                        <li>SQL Update</li>
                                        <li>SQL Delete</li>
                                        <li>SQL Joins</li>
                                        <li>SQL Keys</li>
                                        <li>Project on SQL</li>
                                        <li>Interview Questions on SQL</li>
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Tkinter in Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to Tkinter</li>
                                        <li>First GUI application in Tkinter</li>
                                        <li>Tkinter Frame Widget</li>
                                        <li>Tkinter Labels</li>
                                        <li>Tkinter Buttons</li>
                                        <li>Tkinter Entry</li>
                                        <li>Tkinter CheckBox and CheckButtons</li>
                                        <li>Tkinter ListBox</li>
                                        <li>Tkinter MessageBox</li>
                                        <li>Geometry Management of Tkinter</li>
                                        <li>Binding Functions</li>
                                        <li>Working with connection of TKinter</li>
                                        <li>Projects on TKinter</li>
                                       
                                    </ul>
                                
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Numpy Introduction</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Numpy Introduction</li>
                                        <li>Applications of Numpy</li>  
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Pandas Introduction</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Introduction to Pandas</li>
                                        <li>Application of Pandas</li>     
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Projects on Python Development</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                    <li>Static Terminal Projects</li>
                                    <li>Projects like Billing System</li>
                                    <li>Project like ATM</li>
                                    <li>Projects like handling Pharmacy Management Software</li>                                       
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Interview Questions on Python</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Interview Questions on Python</li>
                                    </ul>
                                
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0" id='accordion'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>
                                
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                         

                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>


        </>
    );
}

export default Python;

