import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import interiorimg from "./interiorImg.jpg";

import "./vedpgcss.css";

function Interior() {
  return (
    <>
      <div id="arctop">
        <h1 id="h1">Interior Designing</h1>
      </div>
      <Container id="contain" fluid>
        <Row className="rows">
          <Col xs={12} md={5}>
            {" "}
            <Card id="card">
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <img src={interiorimg} alt="Interior Designing" />
            </Card>
          </Col>

          {/* second column///////////////////////////////////////// */}

          <Col xs={12} md={6}>
            <Card.Text id="info">
              <Card.Title id="titles">Interior Designing</Card.Title>
              <br></br>
             
              <br></br>
              <p style={{textAlign:"left", color:"black"}}>✅ Residential Interior Design – Stylish and comfortable home interiors tailored to your lifestyle.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Commercial & Office Design – Functional and aesthetically pleasing workspaces to boost productivity.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Luxury Interior Design – Premium designs with high-end finishes for an elegant touch.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Modular Kitchen & Wardrobe – Smart, space-saving solutions for modern living.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Furniture & Decor Selection – Handpicked elements that enhance your space’s beauty.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ 3D Visualization & Planning – Get a clear vision of your dream space before execution.</p>
              <p style={{textAlign:"left", color:"black"}}>✅ Turnkey Interior Solutions – From concept to completion, we handle everything.</p>
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Interior;
